<template>
  <div
    style="
      background-image: url(/media/anjungan/BG-1280x1024.jpg);
      height: 100%;
      background-size: cover;
    "
  >
    <!-- Header::Start -->
    <div class="curved-header sample1">
    <div class="row text-center justify-content-between px-10">
        <div class="col pt-8">
          <img
            style="height: 222px"
            :src="config.city_logo"
            alt="LOGO DESA"
          />
        </div>
        <div class="col-8 pt-8 px-19">
          <h1 style="font-size: 45px;
    color: rgb(29, 172, 116);
    font-weight: 600;">ANJUNGAN MANDIRI</h1>
          <h1
            style="font-size: 70px;
    font-weight: 600;
    color: rgb(29, 172, 116);
    position: relative;
    top: -20px;"
            class="text-uppercase"
          >
            DESA {{ config.village_name }}
          </h1>
          <span style="font-size: 20px;
    text-align: justify;
    color: rgb(59, 76, 66);
    position: relative;
    top: -40px;">{{
            config.address
          }}</span>
        </div>
        <div class="col pt-8">
          <img
            style="height: 211px"
            :src="config.village_chief_photo"
            alt="KEPALA DESA"
          />
        </div>
      </div>
  </div>
    <!-- Header::End -->
    <!-- Menu::Start -->
    <div class="container mt-22">
      <div
        class="row justify-content-center mt-auto"
        style="position: relative; top: 17px"
      >
        <div
          class="col-4 my-2 d-flex justify-content-center"
        >
          <img @click="$router.push('/anjungan-mandiri/layanan-mandiri')" src="/media/anjungan/icon_Layanan-Mandiri.png" alt="" />
        </div>
        <div class="col-4 my-2 d-flex justify-content-center" @click="setModal('profil', 'Profil Desa')">
          <img src="/media/anjungan/icon_Profil-Desa.png" alt="" />
        </div>
        <div
          class="col-4 my-2 d-flex justify-content-center"
        >
          <img @click="$router.push('/anjungan-mandiri/berita-desa')" src="/media/anjungan/icon_Kegiatan-Desa.png" alt="" />
        </div>
      </div>
      <div
        class="row justify-content-center"
        style="position: relative; top: 17px"
      >
        <div class="col-4 my-2 d-flex justify-content-center" >
          <img src="/media/anjungan/icon_Statistik-Desa.png" alt="" @click="setModal('statistik', 'Statistik Desa')" />
        </div>
        <div class="col-4 my-2 d-flex justify-content-center">
          <img src="/media/anjungan/icon_Peta-Desa.png" alt="" @click="setModal('peta', 'Peta Desa')" />
        </div>
        <div class="col-4 my-2 d-flex justify-content-center">
          <img src="/media/anjungan/icon_Kontak.png" alt="" @click="setModal('kontak', 'Kontak Desa')" />
        </div>
      </div>
    </div>
    <!-- Menu::End -->
    <!-- Footer::Start -->
    <!-- <Footer :config="config"/> -->
    <div class="curved-footer sample2">
      <div class="d-flex justify-content-center">
        <h1 style="font-size: 100px; font-weight: 600; color: #1dac74">
          {{ time }}
        </h1>
      </div>
      <div class="d-flex justify-content-center">
        <marquee behavior="" direction="">
          <span
            style="color: #3b4c42; font-size: 30px"
            class="text-uppercase"
            >{{ config.running_text }}</span
          >
        </marquee>
      </div>
    </div>
    <!-- Footer::End -->

    <b-modal id="modal-xl" size="xl" scrollable centered hide-footer>
      <template #modal-header="{ close }">
        <HeaderModalFormLetter :title="modal_title" :close="close" />
      </template>
      <template #modal-title
        ><span style="font-size: 20px" class="font-weight-bold">{{
          modal_title
        }}</span></template
      >
      <Statistik v-if="modal_type == 'statistik'"></Statistik>
      <ProfilDesa v-if="modal_type == 'profil'"></ProfilDesa>
      <PetaDesa v-if="modal_type == 'peta'"></PetaDesa>
      <KontakDesa v-if="modal_type == 'kontak'"></KontakDesa>
    </b-modal>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
// import { setConfiguration } from "@/core/services/jwt.service.js";
import Statistik from "@/view/pages/anjungan-mandiri/StatistikDesa.vue";
import ProfilDesa from "@/view/pages/anjungan-mandiri/InfoDesa.vue";
import KontakDesa from "@/view/pages/anjungan-mandiri/KontakDesa.vue";
import PetaDesa from "@/view/pages/anjungan-mandiri/PetaDesa.vue";
import HeaderModalFormLetter from "@/view/components/anjungan-mandiri/HeaderModalFormLetter.vue";

import Footer from "@/view/components/anjungan-mandiri/Footer.vue";
import {
  setConfiguration,
  getConfiguration,
} from "@/core/services/jwt.service.js";
export default {
  components: {
    Statistik,
    ProfilDesa,
    PetaDesa,
    KontakDesa,
    Footer,
    HeaderModalFormLetter,
  },
  data() {
    return {
      config: getConfiguration() == null ? {} : getConfiguration(),
      interval: null,
      time: null,

      // modal
      modal_title: "",
      modal_type: "",
    };
  },

  methods: {
    async getSetting() {
      let response = await module.get("settings-no-auth/1");
      console.log(
        "get setting no auth ============================================================================================"
      );
      if (response != null) {
        this.config = response;
        setConfiguration(JSON.stringify(response));
      }
    },

    setModal(type, title) {
      this.modal_title = title;
      this.modal_type = type;

      this.$bvModal.show("modal-xl");
    },

    /* Get the documentElement (<html>) to display the page in fullscreen */

    /* View in fullscreen */
    openFullscreen() {
      let elem = document.documentElement;
      console.log("elem", elem);
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    },

    /* Close fullscreen */
    closeFullscreen() {
      let elem = document.documentElement;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    },
  },
  mounted() {
    this.getSetting();
    // setTimeout(() => {
    //   this.openFullscreen();
    // }, 0);
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
  },
  created() {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat("en-GB", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format();
    }, 1000);
  },
};
</script>

<style scoped>
.curved-header {
  height: 221px;
  width: 1280px;
  border: none;
  -webkit-box-shadow: inset 0px -1px 0px black;
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
}
.sample1 {
  border-radius: 0% 0% 150% 150%/0% 0% 70% 70%;
  width: 100%;
  background: white;
}

.curved-footer {
  height: 195px;
  width: 1280px;
  border: none;
  /* -webkit-box-shadow: inset 0px -1px 0px black; */
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
}

.sample2 {
  border-radius: 70% 70% 0% 0%/30% 30% 100% 100%;
  position: fixed;
  bottom: -4px;
  width: 100%;
}
</style>
