<template>
  <div>
    <!-- Menu::Start -->
    <div class="container mt-4">
      <div class="row mb-2">
        <div class="col-md-12">
          <div class="row justify-content-center align-items-center" v-if="displayMap == false" style="height:700px">
            <span class="h2">Mohon tunggu, Sedang memuat peta</span>
          </div>
          <div class="openstreetmap mt-3" v-if="displayMap">
            <l-map style="height: 700px" :zoom="zoom" :center="center">
              <l-tile-layer
                :url="url"
                :attribution="attribution"
              ></l-tile-layer>
              <l-marker :lat-lng="center"></l-marker>
            </l-map>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import { setConfiguration } from "@/core/services/jwt.service.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.imagePath = "";
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  data() {
    return {
      village_info: {},
      config: {},
      interval: null,
      time: null,

      modal2: false,
      hamlets: [],

      displayMap: false,

      zoom: 20,
      center: [-6.30749877, 107.290332172],
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      id: "mapbox/streets-v11",
      newLoc: "",
      newLt: 0,
      newLng: 0,
      locations: [{ name: "Karawang", position: [-6.30749877, 107.290332172] }],
    };
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  methods: {
    async getSetting() {
      let response = await module.get("settings-no-auth/1");
      console.log(
        "get setting no auth ============================================================================================"
      );
      if (response != null) {
        this.config = response;
        setConfiguration(JSON.stringify(response));
      }
    },
  },
  mounted() {
    this.getSetting();
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
  },
  created() {

    setTimeout(() => {
      this.displayMap = true
    }, 1000)
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat("en-GB", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format();
    }, 1000);
  },
};
</script>

<style scoped>
.curved-header {
  height: 270px;
  width: 1280px;
  border: none;
  -webkit-box-shadow: inset 0px -1px 0px black;
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
}

.curved-footer {
  height: 230px;
  width: 1280px;
  border: none;
  /* -webkit-box-shadow: inset 0px -1px 0px black; */
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
}

.sample1 {
  border-radius: 0% 0% 150% 150%/0% 0% 70% 70%;
  width: 100%;
}
.sample2 {
  border-radius: 70% 70% 0% 0%/30% 30% 100% 100%;
  position: absolute;
  bottom: -5px;
  width: 100%;
}

.form-control-lg {
  height: 70px;
}
</style>

<style scoped>
.vue-treeselect--single .vue-treeselect__input-container {
  font-size: inherit;
  height: 70px !important;
}

.vue-treeselect--single .vue-treeselect__input {
  width: 100%;
  height: 70px !important;
}
</style>
